import { useCallback, useMemo, useState } from 'react'
import { Badge, Text } from '@bushelpowered/design-system'
import Link from 'next/link'
import { useAuth } from 'src/auth'
import { DisplayConfig } from 'src/components/designsystem/display-config'
import EditMenuItemLink from 'src/components/shared/EditMenuItemLink'
import DeleteMenuItem from 'src/components/shared/DeleteMenuItem'
import { useDetailModal } from 'src/components/resource'
import {
  MenuItem,
  PersonWithMagnifyingGlassIcon,
  useBreakpoint,
  useDisclosure,
} from 'src/components/designsystem'
import { useGetStaffCustomPagesList } from 'src/data/queries/custom-pages'
import { ACTION_MENU_COLUMN_WIDTH } from 'src/utils/display'
import formatDateWithLastUpdated from 'src/utils/string/format-date-with-last-updated'

export const staffCustomPagesColumnConfig: DisplayConfig<StaffCustomPageItem> = {
  desktopTemplateColumns: (items) => `repeat(${items.length}, 1fr) ${ACTION_MENU_COLUMN_WIDTH}px`,
  items: [
    {
      label: 'Security',
      key: 'isPublic',
      renderValue: ({ data: { isPublic } }) => (isPublic ? 'Public' : 'Private'),
    },
    {
      label: 'Last Updated',
      key: 'updatedAt',
      renderValue: ({ data: { updatedAt } }) => formatDateWithLastUpdated(updatedAt),
    },
    {
      label: 'Status',
      key: 'isPublished',
      renderValue: ({ data: { isPublished } }) => (
        <Badge colorScheme={isPublished ? 'green' : 'yellow'}>
          {isPublished ? 'Published' : 'Draft'}
        </Badge>
      ),
    },
  ],
}

export default function useStaffCustomPagesList() {
  const query = useGetStaffCustomPagesList()
  const detailModal = useDetailModal<StaffCustomPageItem>()
  const deletionModalDisclosure = useDisclosure()
  const [itemToDelete, setItemToDelete] = useState<StaffCustomPageItem>()
  const { isDesktop } = useBreakpoint()
  const { slug } = useAuth()

  const onDelete = useCallback(
    (item: StaffCustomPageItem) => {
      deletionModalDisclosure.onOpen()
      setItemToDelete(item)
    },
    [deletionModalDisclosure]
  )

  const columnConfig = useMemo(
    () =>
      isDesktop
        ? {
            ...staffCustomPagesColumnConfig,
            items: [
              {
                label: 'Page Name',
                key: 'title',
              },
              ...staffCustomPagesColumnConfig.items,
            ],
          }
        : staffCustomPagesColumnConfig,
    [isDesktop]
  )

  const actionMenuConfig = useMemo(
    () => [
      {
        key: 'edit',
        render: (item: StaffCustomPageItem) => (
          <EditMenuItemLink href={[null, slug, 'staff', 'custom-pages', item.id].join('/')} />
        ),
      },
      {
        key: 'delete',
        render: (item: StaffCustomPageItem) => <DeleteMenuItem item={item} onDelete={onDelete} />,
      },
      {
        key: 'view',
        render: (item: StaffCustomPageItem) => (
          <MenuItem
            icon={<PersonWithMagnifyingGlassIcon fontSize="xl" />}
            as={Link}
            href={[null, slug, 'custom-pages', item.id].join('/')}
            onClick={(e) => e.stopPropagation()}
          >
            <Text>View in Customer Portal</Text>
          </MenuItem>
        ),
      },
    ],
    [onDelete, slug]
  )

  return {
    query,
    columnConfig,
    detailModal,
    actionMenuConfig,
    deletionModalDisclosure,
    itemToDelete,
    onDelete,
  }
}
