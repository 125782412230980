import { useQuery } from '@tanstack/react-query'
import { aggregatorService } from 'src/api'
import { useAuth } from 'src/auth'
import { customPagesQueryKeys } from './customPagesQueryKey'

export default function useGetCustomPageById(id?: string) {
  const { isStaff } = useAuth()
  return useQuery({
    queryKey: customPagesQueryKeys.getById(id),
    queryFn: () =>
      id ? aggregatorService.getCustomPageById(id) : Promise.reject(new Error('No id provided')),
    select: (data) => data.data,
    enabled: !!id && !isStaff,
    meta: {
      errorMessage: 'Unable to load. Please Try again',
    },
  })
}
