import ky from 'ky'
import handleRefreshTokenIfExpired from 'src/api/utils/handleRefreshTokenIfExpired'
import { ENV, isServer } from 'src/utils/env'
import { getWindowNextDataProps } from 'src/utils/get-window-next-data'

const { token, slug, installationId, version } = getWindowNextDataProps()

const { userIdpId: queryUserId } = isServer() ? ({} as any) : window.__NEXT_DATA__?.query || {}

export let reportingServiceClient = (function () {
  // Need distinction here since useMerchandiser() is not always available/hydrated
  const isEmailIdpId = queryUserId?.includes('@') as string

  const headers = {
    Authorization: token ? `Bearer ${token}` : undefined,
    'App-Name': ENV.APP_URL,
    'App-Company': slug ?? undefined,
    'App-Version': (version as string) ?? undefined,
    'App-Installation-Id': (installationId as string) ?? undefined,
    ...(!isEmailIdpId && { 'X-Impersonate-Phone': queryUserId }),
    ...(isEmailIdpId && { 'X-Impersonate-IdpId': queryUserId }),
  }

  return ky.create({
    prefixUrl: ENV.REPORTING_SERVICE_API,
    retry: 0,
    timeout: 120000, //2 mins
    headers,
    hooks: isServer()
      ? {}
      : {
          beforeRequest: [handleRefreshTokenIfExpired],
          afterResponse: [
            async (_request, _options, response) => {
              if (!response.ok) {
                try {
                  const body = await response.text()

                  console.error('src/api - api request failed', {
                    url: response.url,
                    status: response.status,
                    body,
                  })

                  response.payload = JSON.parse(body)
                } catch (err) {
                  console.error('src/api - api request failed', {
                    url: response.url,
                    status: response.status,
                  })
                }
              }

              return response
            },
          ],
        },
  })
})()

// UTILS
///////////////////////////////////////////////////////////////////////////////////////////////////

export const updateReportingServiceClient = (config = {}) => {
  reportingServiceClient = reportingServiceClient.extend(config)
}

export const updateReportingServiceToken = (token?: string) => {
  updateReportingServiceClient({
    headers: { Authorization: token ? `Bearer ${token}` : undefined },
  })
}

export const updateProofOfYieldClientHeaders = ({
  slug,
  token,
  installationId,
  userIdpId = undefined,
}: {
  slug: string
  token: string
  installationId: string
  userIdpId?: string
}) => {
  // Need distinction here since useMerchandiser() is not always available/hydrated
  const isEmailIdpId = userIdpId?.includes('@')

  updateReportingServiceClient({
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      'App-Company': slug,
      'App-Installation-Id': installationId,
      'App-Name': ENV.APP_URL,
      'App-Version': ENV.APP_VERSION,
      'X-Impersonate-Phone': userIdpId && !isEmailIdpId ? userIdpId : undefined,
      'X-Impersonate-IdpId': userIdpId && isEmailIdpId ? userIdpId : undefined,
    },
  })
}

export const clearReportingServiceClientHeaderImpersonation = () => {
  updateReportingServiceClient({
    headers: {
      'X-Impersonate-Phone': undefined,
      'X-Impersonate-IdpId': undefined,
    },
  })
}

export const updateReportingServiceClientHeader = (key: string, value: string) => {
  updateReportingServiceClient({
    headers: { [key]: value },
  })
}
