import { queryOptions, useInfiniteQuery, useQuery } from '@tanstack/react-query'
import * as api from 'src/api'
import { DEFAULT_PAGE_SIZE, flattenPages, getAggregatorNextPageParamFactory } from './utils'
import { useMemo } from 'react'

export function useGetCommoditySubgroups() {
  return useQuery({
    queryKey: ['get-commodity-subgroups'],
    queryFn: () => api.aggregatorService.getCommoditySubgroups(),
  })
}

export function useGetActiveUsersFromIdentifiers(ids: string[]) {
  return useQuery({
    queryKey: ['get-active-users-from-identifiers', ids],
    queryFn: () => api.aggregatorService.getActiveUsersFromIdentifiers(ids),
    enabled: ids?.filter(Boolean)?.length > 0,
  })
}

export const getAggregatorAppConfigQueryOptions = queryOptions({
  queryKey: ['get-aggregator-app-config-by-slug'],
  queryFn: () => api.aggregatorService.getAggregatorAppConfigBySlug(),
})

export function useTenantId() {
  return useQuery({
    ...getAggregatorAppConfigQueryOptions,
    select: (data) => data.tenantId,
  })
}

export default function useCustomPagesNavigationList() {
  return useQuery({
    ...getAggregatorAppConfigQueryOptions,
    select: (data) => data.data.navigation.customPages,
  })
}

export function useGetAllStaffUsers({ name }: { name?: string }) {
  const query = useInfiniteQuery({
    queryKey: ['get-all-staff-users', name],
    queryFn: ({ pageParam }) =>
      api.aggregatorService.getAllStaffUsers({
        name,
        pagination: {
          token: pageParam,
          pageSize: DEFAULT_PAGE_SIZE,
        },
      }),
    initialPageParam: null,
    meta: {
      errorMessage: 'Unable to retrieve staff users, please try again.',
    },
    getNextPageParam: getAggregatorNextPageParamFactory<StaffUser>(DEFAULT_PAGE_SIZE),
  })

  return { query, data: useMemo(() => flattenPages(query.data), [query.data]) }
}

export function useBranding() {
  return useQuery({
    ...getAggregatorAppConfigQueryOptions,
    select: (data) => data.data.branding,
  })
}

export function useAggregatorConfig() {
  return useQuery({
    ...getAggregatorAppConfigQueryOptions,
    select: (data) => data.data.config,
  })
}
