import { useState, useCallback, useEffect } from 'react'
import { create } from 'zustand'
import { useMakeSidebarGroups } from 'src/components/layout/sidebar/helpers'
import { useConfig } from 'src/data/config'
import * as api from 'src/api'
import { Step } from './Step'

type ReqParamsList = Record<string, string>[]

const INITIAL_STATE = {
  isOpen: false,
  step: Step.START,
  history: [Step.START] as Step[],
  reqParams: [] as ReqParamsList,
}

type ReportIssueStore = {
  isOpen: boolean
  step: Step
  history: Step[]
  reqParams: ReqParamsList

  open: () => void
  close: () => void
  stepForward: (step: Step) => void
  stepForwardWithData: (step: Step, data: Record<string, string>) => void
  stepBack: () => void
  submitForm: (data?: Record<string, string>) => Promise<void>
}

export const useReportIssueStore = create<ReportIssueStore>()((set, get) => ({
  ...INITIAL_STATE,

  open: () => set({ isOpen: true }),

  close: () => {
    set({ isOpen: false })

    // Prevent UI jank while modal is animating away
    setTimeout(() => set(INITIAL_STATE), 1000)
  },

  stepForward: (step: Step) => {
    const { history } = get()

    set({ step, history: [...history, step] })
  },

  stepForwardWithData: (step: Step, data: Record<string, string>) => {
    const { history, reqParams } = get()

    set({ step, history: [...history, step], reqParams: [...reqParams, data] })
  },

  stepBack: () => {
    const { history, reqParams } = get()

    set({
      step: history[history.length - 2],
      history: history.slice(0, history.length - 1),
      reqParams: reqParams.slice(0, reqParams.length - 1),
    })
  },

  submitForm: async (data?: Record<string, string>) => {
    const { reqParams, stepForwardWithData } = get()

    try {
      await api.centre.reportProblem(makeRequestBody(data ? [...reqParams, data] : reqParams))
      stepForwardWithData(Step.SUBMIT_SUCCESS, data)
    } catch (err) {
      console.log('submit err', err)
      stepForwardWithData(Step.SUBMIT_ERROR, data)
    }
  },
}))

function makeRequestBody(params: ReqParamsList) {
  return params.reduce((acc, param) => ({ ...acc, ...param }), {})
}

export function useFeatureSelection() {
  const { isFeatureEnabled } = useConfig()

  const featureList = useMakeSidebarGroups({ slug: '' })
    .reduce((acc, group) => [...acc, ...group.items], [])
    .filter((item) => isFeatureEnabled(item.featureName, item.isEnabled))

  return [
    ...featureList,
    {
      ariaLabel: 'Other',
      featureName: 'other',
    },
  ]
}

function useGetFeatureName(reqParams: ReqParamsList) {
  const featureName = makeRequestBody(reqParams).feature_name
  const feature = useMakeSidebarGroups({ slug: '' })
    .reduce((acc, group) => [...acc, ...group.items], [])
    .find((item) => item.featureName === featureName)

  return feature?.ariaLabel ?? 'Other'
}

export function useSubmitStep() {
  const { submitForm, reqParams } = useReportIssueStore()
  const [value, setValue] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasError, setHasError] = useState(false)
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      if (!value || value.trim().length === 0) {
        setHasError(true)
        return
      }

      if (hasError) setHasError(false)

      setIsSubmitting(true)
      await submitForm({ message: value })
      setIsSubmitting(false)
    },
    [value, hasError, submitForm]
  )

  return {
    featureName: useGetFeatureName(reqParams),
    value,
    setValue,
    hasError,
    isSubmitting,
    onSubmit,
  }
}

export function useUnload(onUnload) {
  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [onUnload])
}
