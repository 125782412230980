import { ReactNode } from 'react'
import { useFeatureFlag } from 'configcat-react'
import { FeatureName } from 'src/__fixtures__/featureConfig'
import { AUTHZ_PERMISSIONS } from 'src/api/bidl/iam/authz-permissions'
import { GenericNonIdealState } from 'src/components/designsystem'
import StaffPageCheck from 'src/components/layout/StaffPageCheck'
import StaffPermissionCheck from 'src/components/layout/StaffPermissionCheck'
import { LoadingState } from 'src/components/resource'
import { AggregatorConfigCheck } from 'src/components/resource/AggregatorConfigCheck'
import { NoAccountsPageCheck } from 'src/components/resource/NoAccountsPageCheck'
import { CONFIGCAT_FLAGS } from 'src/utils/config-cat'

export const CUSTOM_PAGES_FEATURE_NAME: FeatureName = 'customPages'

export default function StaffCustomPagesPageChecks({
  children,
}: Readonly<{ children: ReactNode }>) {
  const { value: isFeatureEnabled, loading } = useFeatureFlag(
    CONFIGCAT_FLAGS.isStaffCustomPagesEnabled,
    false
  )

  if (loading) {
    return <LoadingState mt={16} />
  }

  if (!isFeatureEnabled) {
    return (
      <GenericNonIdealState
        isVerticallyCentered
        illustration="tumbleweed"
        illustrationAltText="Feature not enabled"
        header="Feature not enabled"
        subHeader="Unsure why this page is disabled? Contact your company for more information."
      />
    )
  }

  return (
    <NoAccountsPageCheck>
      <StaffPageCheck>
        <StaffPermissionCheck permission={AUTHZ_PERMISSIONS.CUSTOM_PAGES_MANAGE}>
          <AggregatorConfigCheck
            featureName="Custom Pages"
            configKeyName={CUSTOM_PAGES_FEATURE_NAME}
          >
            {children}
          </AggregatorConfigCheck>
        </StaffPermissionCheck>
      </StaffPageCheck>
    </NoAccountsPageCheck>
  )
}
