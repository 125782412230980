import getConfig from 'next/config'
import { Level } from 'rollbar'

export interface EnvVars {
  APP_URL?: string
  APP_VERSION?: string
  BUILD_ID?: string
  MOCK_MODE?: boolean
  MOCK_NO_ACCOUNTS?: boolean
  MOCK_NO_AUTH?: boolean
  MOCK_MERCH_MODE?: boolean
  NODE_ENV: 'development' | 'test' | 'production'
  OIDC_CLIENT_ID?: string
  OIDC_KC_URL?: string
  OIDC_URL?: string
  OAUTH_ISSUER?: string
  CENTRE_API?: string
  FLAGR_API?: string
  FUTURES_SERVICE_API?: string
  OFFERS_SERVICE_API: string
  PAYMENTS_COMPANY_API?: string
  PAYMENTS_PAYMENTS_API?: string
  PAYMENTS_USERS_API?: string
  ANALYTICS_SERVICE_API?: string
  REPORTING_SERVICE_API?: string
  BIDL_API?: string
  AGGREGATOR_API?: string
  MATOMO_URL?: string
  MATOMO_SITE_ID?: string
  ROLLBAR_CLIENT_TOKEN?: string
  BUSHEL_ENVIRONMENT?: 'local' | 'test' | 'dev' | 'uat' | 'prod' | string
  LOG_REPORT_LEVEL?: Level
  ENABLE_ANALYTICS?: boolean
  DATADOG_RUM_APP_ID?: string
  DATADOG_RUM_CLIENT_TOKEN?: string
  DATADOG_RUM_SERVICE?: string
  NOTIFY_API_URL: string
  NOTIFY_CLIENT_TOKEN: string
  INTERCOM_APP_ID: string
  BUSHEL_PRODUCT_TRADE_URL: string
  BUSHEL_PRODUCT_FULFILLMENT_URL: string
  BUSHEL_PRODUCT_WEBSITE_URL: string
  POWERED_BY_BUSHEL_URL: string
  CONFIGCAT_SDK_KEY: string
  CONFIGCAT_BASE_URL: string
  KEYCLOAK_AUGMENT_WEB_API_URL: string
}

export interface ServerEnvVars {
  OAUTH_CLIENT_ID: string
  OAUTH_CLIENT_SECRET: string
  TEMP_SLUG_ACCESS_CODES?: string
  ROLLBAR_SERVER_TOKEN?: string
  SCOULAR_PDF_OAUTH_CLIENT_ID?: string
  SCOULAR_PDF_OAUTH_CLIENT_SECRET?: string
  SCOULAR_PDF_OAUTH_TOKEN_HOST?: string
  SCOULAR_PDF_OAUTH_TOKEN_PATH?: string
  SCOULAR_PDF_OAUTH_RESOURCE?: string
  SCOULAR_PDF_FILE_URL?: string

  SCOULAR_PDF_OAUTH2_TOKEN_HOST?: string
  SCOULAR_PDF_OAUTH2_SETTLEMENTS_ENDPOINT?: string
  SCOULAR_PDF_OAUTH2_SETTLEMENTS_CLIENT_ID?: string
  SCOULAR_PDF_OAUTH2_SETTLEMENTS_CLIENT_SECRET?: string
  SCOULAR_PDF_OAUTH2_SETTLEMENTS_SCOPE?: string
  SCOULAR_PDF_OAUTH2_CONTRACTS_ENDPOINT?: string
  SCOULAR_PDF_OAUTH2_CONTRACTS_CLIENT_ID?: string
  SCOULAR_PDF_OAUTH2_CONTRACTS_CLIENT_SECRET?: string
  SCOULAR_PDF_OAUTH2_CONTRACTS_SCOPE?: string

  ADM_PDF_OAUTH_TOKEN_GRANT_TYPE?: string
  ADM_PDF_OAUTH_TOKEN_CLIENT_ID?: string
  ADM_PDF_OAUTH_TOKEN_CLIENT_SECRET?: string
  ADM_PDF_OAUTH_TOKEN_SCOPE?: string
  ADM_PDF_OAUTH_TOKEN_PATH?: string
  ADM_PDF_FILE_URL?: string

  NEXTAUTH_URL?: string
  NEXTAUTH_SECRET?: string
  NEXTAUTH_DEBUG?: boolean
}

interface NextConfigEnvVars {
  publicRuntimeConfig: EnvVars
  serverRuntimeConfig: ServerEnvVars
}

const { publicRuntimeConfig, serverRuntimeConfig: SERVER_ENV }: NextConfigEnvVars =
  getConfig() ?? getTestConfig()

function getTestConfig(): NextConfigEnvVars {
  return {
    publicRuntimeConfig: {
      APP_URL: 'http://localhost:3000',
      APP_VERSION: '0.0.0-alpha',
      MOCK_MODE: true,
      MOCK_NO_ACCOUNTS: false,
      MOCK_NO_AUTH: false,
      NODE_ENV: 'test',
      OIDC_CLIENT_ID: 'bushel-grower-webapp',
      OIDC_KC_URL: 'http://localhost:8080/auth',
      OIDC_URL: 'http://localhost:8080/auth/realms/bushel',
      CENTRE_API: 'http://localhost:8000',
      FLAGR_API: 'https://flagr.dev.bushelops.com/api/v1',
      FUTURES_SERVICE_API: 'https://futures.bushelops.com',
      OFFERS_SERVICE_API: 'https://offers.bushelops.com',
      PAYMENTS_COMPANY_API: 'https://payments-back-end-dev-company.dev.bushelops.com',
      PAYMENTS_PAYMENTS_API: 'https://payments-back-end-dev-payments.dev.bushelops.com',
      PAYMENTS_USERS_API: 'https://payments-back-end-dev-users.dev.bushelops.com',
      ANALYTICS_SERVICE_API: 'https://bushel-data-analytics-microservice-dev.dev.bushelops.com',
      REPORTING_SERVICE_API: 'https://bushel-reporting-dev.dev.bushelops.com',
      BIDL_API: 'https://bidl.labrador.us.dev.bushelops.com',
      AGGREGATOR_API: 'https://bushel-web-aggregator-dev.dev.bushelops.com',
      MATOMO_URL: 'https://matomo.dev.bushelops.com',
      MATOMO_SITE_ID: '12',
      ENABLE_ANALYTICS: false,
      BUSHEL_ENVIRONMENT: 'test',
      NOTIFY_API_URL: 'https://notify.myriadapps.com/api',
      NOTIFY_CLIENT_TOKEN: 'test-token',
      INTERCOM_APP_ID: 'test-app-id',
      BUSHEL_PRODUCT_TRADE_URL: 'https://mdemo.cqg.com/bushel/desktop/logon',
      BUSHEL_PRODUCT_FULFILLMENT_URL: 'https://bushel-fulfillment-portal-dev.dev.bushelops.com/',
      BUSHEL_PRODUCT_WEBSITE_URL: 'https://hostadmin.farmcentric.com/admin/adminIndex.aspx',
      POWERED_BY_BUSHEL_URL: 'https://bushelfarm.com/bushel-for-farmers',
      CONFIGCAT_SDK_KEY: 'configcat-proxy/customerportal-web-portal-prod',
      CONFIGCAT_BASE_URL: 'https://configcat.bushelops.com',
      KEYCLOAK_AUGMENT_WEB_API_URL: 'https://keycloak-augment-web.dev.bushelops.com',
    },

    serverRuntimeConfig: {
      TEMP_SLUG_ACCESS_CODES: 'secret',
      ROLLBAR_SERVER_TOKEN: 'rollbar-server-token',
      SCOULAR_PDF_OAUTH_CLIENT_ID: 'scoular-pdf-oauth-client-id',
      SCOULAR_PDF_OAUTH_CLIENT_SECRET: 'scoular-pdf-oauth-client-secret',
      SCOULAR_PDF_OAUTH_TOKEN_HOST: 'https://scoular-oauth-host',
      SCOULAR_PDF_OAUTH_TOKEN_PATH: '/oauth/token/',
      SCOULAR_PDF_OAUTH_RESOURCE: 'oauth-resource',
      SCOULAR_PDF_FILE_URL: 'https://scoular-file-host/gnv/fbo/projects',
      SCOULAR_PDF_OAUTH2_TOKEN_HOST: 'SCOULAR_PDF_OAUTH2_TOKEN_HOST',
      SCOULAR_PDF_OAUTH2_SETTLEMENTS_ENDPOINT: 'SCOULAR_PDF_OAUTH2_SETTLEMENTS_ENDPOINT',
      SCOULAR_PDF_OAUTH2_SETTLEMENTS_CLIENT_ID: 'SCOULAR_PDF_OAUTH2_SETTLEMENTS_CLIENT_ID',
      SCOULAR_PDF_OAUTH2_SETTLEMENTS_CLIENT_SECRET: 'SCOULAR_PDF_OAUTH2_SETTLEMENTS_CLIENT_SECRET',
      SCOULAR_PDF_OAUTH2_SETTLEMENTS_SCOPE: 'SCOULAR_PDF_OAUTH2_SETTLEMENTS_SCOPE',
      SCOULAR_PDF_OAUTH2_CONTRACTS_ENDPOINT: 'SCOULAR_PDF_OAUTH2_CONTRACTS_ENDPOINT',
      SCOULAR_PDF_OAUTH2_CONTRACTS_CLIENT_ID: 'SCOULAR_PDF_OAUTH2_CONTRACTS_CLIENT_ID',
      SCOULAR_PDF_OAUTH2_CONTRACTS_CLIENT_SECRET: 'SCOULAR_PDF_OAUTH2_CONTRACTS_CLIENT_SECRET',
      SCOULAR_PDF_OAUTH2_CONTRACTS_SCOPE: 'SCOULAR_PDF_OAUTH2_CONTRACTS_SCOPE',
      ADM_PDF_OAUTH_TOKEN_GRANT_TYPE: 'client_credentials',
      ADM_PDF_OAUTH_TOKEN_CLIENT_ID: 'adm-pdf-oauth-client-id',
      ADM_PDF_OAUTH_TOKEN_CLIENT_SECRET: 'adm-pdf-oauth-client-secret',
      ADM_PDF_OAUTH_TOKEN_SCOPE: 'uuid/.default',
      ADM_PDF_OAUTH_TOKEN_PATH: 'https://adm-oauth-host/oauth2/v2.0/token',
      ADM_PDF_FILE_URL:
        'https://adm-file-host/extapp/GrainBridgeDocRetrievalAPI/v3/api/docretrieval',
      OAUTH_CLIENT_ID: '',
      OAUTH_CLIENT_SECRET: '',
    } as ServerEnvVars,
  }
}

export const isServer = () => typeof window === 'undefined'
export const isClient = () => typeof window !== 'undefined'

export const DEV_CENTRE_API_COOKIE = 'bushel-web-debug-centre-target'

export function getDevCentreApiUrl(cookies: Record<string, string> = {}) {
  const isDevOrLocal = ['dev', 'yeet', 'acadia', 'qa', 'local'].includes(
    publicRuntimeConfig.BUSHEL_ENVIRONMENT ?? ''
  )
  const isMockMode = publicRuntimeConfig.MOCK_MODE

  // Only respect this cookie locally or in dev, and NOT in mock mode
  if (isDevOrLocal && !isMockMode) {
    return cookies?.[DEV_CENTRE_API_COOKIE] || null
  }

  return null
}

export { publicRuntimeConfig as ENV, SERVER_ENV }

export default publicRuntimeConfig
