import { insecureClientStorage } from 'src/utils/storage/insecure/insecure-client-storage'
import { Optional } from 'src/utils/types/optional'

const oldKey = 'bwp-cash-bid-favorites'
const newKey = 'bwp-favorite-location-ids'

export async function migrateFavoriteLocationIds() {
  const oldData = getOldData()

  if (oldData) {
    insecureClientStorage.set(newKey, oldData)
    localStorage.removeItem(oldKey)
  }
}

function getOldData(): Optional<object> {
  const oldDataString = localStorage.getItem(oldKey)

  try {
    return JSON.parse(oldDataString)
  } catch (error) {
    return undefined
  }
}
