import { useController } from 'react-hook-form'
import {
  Card,
  FormControl,
  FormLabel,
  HStack,
  InformationCircleIcon,
  MobileTooltip,
  type TooltipProps,
} from 'src/components/designsystem'
import ToggleButtonGroup from 'src/components/shared/ToggleButtonGroup'

export default function SecurityToggle() {
  const {
    field: { value, onChange },
    formState,
  } = useController({ name: 'isPublic' })

  return (
    <FormControl isInvalid={!!formState.errors.isPublic} isRequired>
      <Card variant="outline" boxShadow={0} w="full">
        <HStack p={5} alignItems="center" justifyContent="space-between">
          <HStack>
            <FormLabel m={0}>Security</FormLabel>
            <SecurityToggleTooltip />
          </HStack>
          <ToggleButtonGroup labels={['Public', 'Private']} value={value} onChange={onChange} />
        </HStack>
      </Card>
    </FormControl>
  )
}

export function SecurityToggleTooltip(props: Readonly<Partial<TooltipProps>>) {
  return (
    <MobileTooltip
      hasArrow
      placement="top"
      offset={[50, 0]}
      textAlign="center"
      label={
        <>
          <div>Public will be viewable by all visitors.</div>
          <div>
            Private will be shown only to growers who are logged in and have an account with you.
          </div>
        </>
      }
      innerProps={{ rounded: 'full' }}
      {...props}
    >
      <InformationCircleIcon fontSize="2xl" cursor="help" />
    </MobileTooltip>
  )
}
