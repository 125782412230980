import { bidlClient } from 'src/api/bidl/client'

export const tenantServiceRequests = {
  getAllStaffUsers: (tenantId: string, options?: GetAllStaffUserOptions) =>
    bidlClient
      .post('api/platform/user/v1/GetAllStaffUsers', {
        json: options,
        headers: {
          'X-Bushel-Tenant': tenantId,
        },
      })
      .json<GetAllStaffUserResponse>(),
}
