import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRouter } from 'next/router'
import { useAuth } from 'src/auth'
import { Text, useToast } from 'src/components/designsystem'
import { useStaffSaveCustomPage } from 'src/data/queries/custom-pages'
import { customPageFormSchema } from './customPageFormSchema'

export const emptyParagraph: CustomPageParagraph = { title: '', description: '' }
export const emptyFile: CustomPageFile = { id: '', name: '', fileName: '', fileLink: '' }
export const MAX_PARAGRAPHS = 5
export const MAX_FILES = 50
export const MAX_TITLE_LENGTH = 50
export const MAX_PARAGRAPH_LENGTH = 1000

export type CustomPageFormValues = {
  files: CustomPageFile[]
  filesTitle?: string
  headerImageFileLink?: string
  headerImageId?: string
  isPublic: boolean
  paragraphs: CustomPageParagraph[] // First paragraph, title is required
  title: string
}

export default function useStaffCustomPageForm(customPageDetail: StaffCustomPageDetails) {
  const toast = useToast()
  const { push } = useRouter()
  const { slug } = useAuth()

  const isPublished =
    customPageDetail?.publishedAt !== undefined ? !!customPageDetail?.publishedAt : false

  const form = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    delayError: 200, // Delay error messages to prevent flashing during close
    resolver: yupResolver(customPageFormSchema),
    defaultValues: {
      files: customPageDetail?.files.length ? customPageDetail?.files : [emptyFile],
      filesTitle: customPageDetail?.filesTitle ?? '',
      headerImageFileLink: customPageDetail?.headerImageFileLink ?? '',
      headerImageId: customPageDetail?.headerImageId,
      isPublic: customPageDetail?.isPublic ?? true,
      paragraphs: customPageDetail?.paragraphs.map((paragraph) => ({
        title: paragraph.title ?? '',
        description: paragraph.description ?? '',
      })) ?? [emptyParagraph],
      title: customPageDetail?.title ?? '',
    },
  })

  const mutation = useStaffSaveCustomPage(customPageDetail?.id, {
    onSuccess: ({ data }, variables) => {
      toast({
        title: variables?.publish ? 'Published' : '',
        description: variables?.publish ? (
          <Text>
            View the published version in{' '}
            <Link href={[null, slug, 'custom-pages', data.id].join('/')} textDecoration="underline">
              Customer Portal
            </Link>
          </Text>
        ) : (
          'Custom page saved successfully'
        ),
        status: 'success',
        duration: 5000,
        isClosable: true,
      })

      return push([null, slug, 'staff', 'custom-pages'].join('/'))
    },
    onError: () => {
      toast({
        title: 'Error',
        description: `Please try again.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const submit = useCallback(
    (data: CustomPageFormValues, publish: boolean) => {
      const paragraphs = data.paragraphs.filter(({ title, description }) => {
        return !(title.length === 0 && description.length === 0)
      })

      if (paragraphs.length === 0 || paragraphs[0].title.length === 0) {
        form.setError('paragraphs.0.title', {
          type: 'required',
          message: 'Paragraph title is required',
        })
        return
      }

      const files = data.files
        .filter(({ name, fileName, fileLink }) => {
          return !(name.length === 0 && fileName.length === 0 && fileLink.length === 0)
        })
        .map(({ id, name }) => ({ id, name: name.length > 0 ? name : undefined }))

      return mutation.mutateAsync({
        title: data.title,
        files,
        filesTitle: data.filesTitle,
        headerImageId: data.headerImageId,
        isPublic: data.isPublic,
        paragraphs,
        publish,
      })
    },
    [form, mutation]
  )

  const onSave = useCallback(
    (data: CustomPageFormValues) => submit(data, isPublished),
    [isPublished, submit]
  )

  const onPublish = useCallback((data: CustomPageFormValues) => submit(data, true), [submit])

  return {
    form,
    onSave: form.handleSubmit(onSave),
    onPublish: form.handleSubmit(onPublish),
    isPublished,
  }
}
