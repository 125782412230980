import { ChangeEvent, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Spinner,
  Stack,
  Text,
} from 'src/components/designsystem'
import {
  fileExtensionToMediaType,
  useStaffCreateCustomPageFile,
} from 'src/data/queries/custom-pages'
import type { CustomPageFormValues } from './useStaffCustomPageForm'

const MAX_FILE_SIZE = 2 * 1024 * 1024 // 2 MB in bytes
const ALLOWED_FILE_TYPES = ['application/pdf'] // If more file types are added, update the error message in handleFileChange

export default function FileFormCard({
  index,
  onClear,
}: Readonly<{
  index: number
  onClear?: () => void
}>) {
  const {
    register,
    setError,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext<CustomPageFormValues>()
  const errorPath = `files.${index}.id` as const

  const { mutate, isPending, isError, reset, variables } = useStaffCreateCustomPageFile({
    onSuccess: (data) => {
      setValue(errorPath, data.id)
    },
    onError: () => {
      setError(errorPath, {
        type: 'manual',
        message: 'Failed to upload',
      })
    },
  })

  const onReset = useCallback(() => {
    clearErrors(`files.${index}`)
    reset()
  }, [clearErrors, index, reset])

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0]
      event.target.value = ''
      if (!file) return

      onReset()

      if (!ALLOWED_FILE_TYPES.includes(file?.type)) {
        setError(errorPath, {
          type: 'manual',
          message: 'Your file must be a PDF',
        })
        return
      }

      if (file?.size > MAX_FILE_SIZE) {
        setError(errorPath, {
          type: 'manual',
          message: `Your file is larger than the allowed ${MAX_FILE_SIZE}MB limit. Please try a smaller file.`,
        })
        return
      }

      const reader = new FileReader()
      reader.onloadend = async () => {
        if (typeof reader.result === 'string') {
          const base64String = reader.result.split(',')[1] // Remove the data URL prefix
          const [, fileExtension] = file.name.split('.')
          mutate({
            file: base64String,
            fileName: file.name,
            fileType: 'custom_page_file',
            sourceMediaType: fileExtensionToMediaType(fileExtension),
          })
          setValue(`files.${index}.fileName`, file.name)
        }
      }
      reader.readAsDataURL(file)
    },
    [errorPath, index, mutate, onReset, setError, setValue]
  )

  const filesError = errors.files?.[index]
  const fileInputId = `file-input-${index}`

  return (
    <Card variant="outline" opacity={isPending ? 0.6 : undefined}>
      <CardBody>
        <Stack gap={6}>
          <Input type="hidden" {...register(`files.${index}.id`)} />
          <FormControl isInvalid={!!filesError?.name} isDisabled={isPending}>
            <FormLabel>Name file (as it will appear on the page)</FormLabel>
            <Input {...register(`files.${index}.name`)} placeholder="Enter file name" />
            <FormErrorMessage>{filesError?.name?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!filesError?.id?.message || isError} isDisabled={isPending}>
            <FormLabel>File upload (PDF files only)</FormLabel>
            <Input
              type="file"
              accept={ALLOWED_FILE_TYPES.join(',')}
              display="none"
              id={fileInputId}
              onChange={handleFileChange}
            />
            <InputGroup>
              <InputLeftAddon
                bgColor="blue.400"
                color="white"
                fontWeight="semibold"
                as="label"
                htmlFor={fileInputId}
                cursor="pointer"
                pointerEvents={isPending ? 'none' : undefined}
                _hover={{ bgColor: 'blue.500' }}
                minW="122px"
                justifyContent="center"
              >
                {isPending ? <Spinner size="xs" color="white" /> : 'Browse files'}
              </InputLeftAddon>
              <Input {...register(`files.${index}.fileName`)} pointerEvents="none" />
            </InputGroup>
            <FormErrorMessage>
              <HStack gap={1}>
                <Text>{filesError?.id?.message}</Text>
                {isError && (
                  <Button
                    variant="link"
                    onClick={() => {
                      setError(errorPath, null)
                      mutate(variables)
                    }}
                    size="sm"
                  >
                    Retry
                  </Button>
                )}
              </HStack>
            </FormErrorMessage>
          </FormControl>
          <Button
            variant="link"
            alignSelf="flex-start"
            ml={4}
            isDisabled={isPending}
            onClick={() => {
              onReset()
              onClear()
            }}
          >
            {index === 0 ? 'Clear' : 'Remove'}
          </Button>
        </Stack>
      </CardBody>
    </Card>
  )
}
