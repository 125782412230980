import { memo, Fragment, PropsWithChildren, FunctionComponent } from 'react'
import {
  Box,
  Flex,
  FlexProps,
  Grid,
  Text,
  Tooltip,
  useBreakpoint,
} from 'src/components/designsystem'
import { renderOrEmDash } from 'src/components/resource/helpers'
import type { DisplayConfigItem } from 'src/components/designsystem/display-config/types'
import { useFeatureFlag } from 'configcat-react'
import { CONFIGCAT_FLAGS } from 'src/utils/config-cat'
import { labelToTestId } from 'src/utils/string/label-to-test-id'

export * from 'src/components/designsystem/display-config/types'

/**
 * Renders a table body row
 */

interface RowRendererProps<Item> {
  displayConfig: DisplayConfigItem<Item>[]
  item: Item
  showDivider: (args: { index: number }) => boolean
}

const RowRenderer = memo(function RowRenderer<Item>({
  displayConfig,
  item,
  showDivider,
}: RowRendererProps<Item>) {
  return (
    <>
      {displayConfig.map((config, index) => {
        const key = config.label || config.key || index

        if (config.renderCell) {
          return <Fragment key={key}>{config.renderCell({ data: item, config })}</Fragment>
        }

        return (
          <RowCell
            key={key}
            config={config}
            item={item}
            showDivider={showDivider({ index })}
            RowComponent={config.RowComponent}
            LabelComponent={config.LabelComponent}
            ValueComponent={config.ValueComponent}
          />
        )
      })}
    </>
  )
})

/**
 * Renders an item's overall cell.
 * Handles several basic behaviors (padding, responsive label, divider)
 * May be completely customized via `renderCell()`
 */
export interface RowCellProps<Item> {
  config: DisplayConfigItem<Item>
  item: Item
  showDivider?: boolean
  RowComponent?: FunctionComponent<RowComponentProps<Item>>
  LabelComponent?: FunctionComponent<CellProps<Item>>
  ValueComponent?: FunctionComponent<CellProps<Item>>
}

function RowCell<Item>({
  config,
  item,
  showDivider = false,
  RowComponent = DefaultRowComponent,
  LabelComponent = CellLabel,
  ValueComponent = ResponsiveBoldTextValue,
}: RowCellProps<Item>) {
  return (
    <RowComponent
      config={config}
      label={<LabelComponent {...{ config, item }} />}
      value={<ValueComponent {...{ config, item }} />}
      showDivider={showDivider}
    />
  )
}

export interface RowComponentProps<Item> {
  config: DisplayConfigItem<Item>
  label: JSX.Element
  value: JSX.Element
  showDivider?: boolean
}

export function DefaultRowComponent<Item>({
  label,
  value,
  showDivider = false,
  config: _,
  ...flexProps
}: RowComponentProps<Item> & FlexProps) {
  return (
    <Flex
      alignItems={['flex-start', null, null, 'center']}
      minH={['40px', null, null, '48px']}
      py={[2, null, null, 0]}
      pr={[0, null, null, 2, 8]}
      borderBottom={showDivider ? '1px solid' : undefined}
      borderColor="gray.300"
      {...flexProps}
    >
      {label}
      {value}
    </Flex>
  )
}

interface CellProps<Item> {
  config: DisplayConfigItem<Item>
  item: Item
}

/**
 * Renders an item's label (only shows on mobile/tablet)
 */
function CellLabel<Item>({ config, item }: CellProps<Item>) {
  const labelText = deriveLabel({ config, item })
  const { isDesktop } = useBreakpoint()

  if (isDesktop) return null

  return (
    <Text display="flex" mr={6} data-testid={`column-header-${labelToTestId(labelText)}`}>
      {labelText}
    </Text>
  )
}

/**
 * Renders a an item's value.
 * The value is determined via `key` or `getValue()`.
 * If this value is not truthy, an em-dash will be rendered (TODO: Default value instead)
 * The value will be rendered, unless `renderValue()` is supplied, which overrides value formatting (concatenation, date-formatting, etc.)
 * The `ValueCellComponent` can be a fully custom component, or one of these provided options (see component comments):
 *  - `BoxValue`
 *  - `BoldTextValue`
 *  - `ResponsiveBoldTextValue` (default)
 */

type ValueContentProps<Item> = PropsWithChildren<{ config: DisplayConfigItem<Item> }>

interface CellProps<Item> {
  config: DisplayConfigItem<Item>
  item: Item
}

/**
 * This is the default wrapper for the value cell content.
 * It positions the value cell relating to the label cell and handles text alignment.
 */
function DefaultValueWrapper<Item>({ children, config }: ValueContentProps<Item>) {
  const desktopTextAlign = config.textAlign ?? 'left'

  return (
    <Box
      flex={1}
      // On tablet/mobile, _all_ values are right-aligned
      textAlign={['right', null, null, desktopTextAlign]}
    >
      {children}
    </Box>
  )
}

/**
 * A plain box for non-plaintext cell values (badges, buttons, etc.)
 */
export function BoxValue<Item>({ config, item }: CellProps<Item>) {
  return (
    <DefaultValueWrapper config={config}>
      <Box aria-label={deriveLabel({ config, item })}>{deriveValue({ config, item })}</Box>
    </DefaultValueWrapper>
  )
}

/**
 * Text node that always uses `body-bold` (collapsable detail table)
 */
export function BoldTextValue<Item>({ config, item }: CellProps<Item>) {
  return (
    <DefaultValueWrapper config={config}>
      <Text aria-label={deriveLabel({ config, item })} textStyle="body-bold">
        {deriveValue({ config, item })}
      </Text>
    </DefaultValueWrapper>
  )
}

/**
 * Text node that will responsively switch from `body` on desktop, to `body-bold` on mobile (resource list)
 */
export function ResponsiveBoldTextValue<Item>({ config, item }: CellProps<Item>) {
  const value = deriveValue({ config, item })
  const { value: showTooltipForAddendumColumns } = useFeatureFlag(
    CONFIGCAT_FLAGS.showTooltipForAddendumColumns,
    false
  )
  const showToolTip = config.showTooltip
  const label = deriveLabel({ config, item })
  const testId = `row-datapoint-${labelToTestId(label)}`
  return (
    <DefaultValueWrapper config={config}>
      {showTooltipForAddendumColumns ? (
        <Tooltip label={value} isDisabled={!showToolTip}>
          <Text
            aria-label={label}
            textStyle={['body-bold', null, null, 'body']}
            whiteSpace="pre-wrap"
            overflowWrap="anywhere"
            overflow="hidden"
            noOfLines={2}
            data-testid={testId}
          >
            {value}
          </Text>
        </Tooltip>
      ) : (
        <Text
          aria-label={label}
          textStyle={['body-bold', null, null, 'body']}
          data-testid={testId}
          whiteSpace="pre-wrap"
          overflowWrap="anywhere"
        >
          {value}
        </Text>
      )}
    </DefaultValueWrapper>
  )
}

export function deriveLabel<Item>({ config, item }: CellProps<Item>) {
  return config.getLabel ? config.getLabel({ data: item }) : config.label
}

export function deriveValue<Item>({ config, item }: CellProps<Item>) {
  const { key, getValue, renderValue } = config
  const value = getValue ? getValue({ data: item }) : item?.[key]
  const renderedValue = renderValue ? renderValue({ data: item }) : value

  return renderOrEmDash({ value, itemToRender: renderedValue })
}

const DisplayConfig = {
  HeaderRow: Grid,
  BodyRow: Grid,
  RowRenderer,
  RowCell,
  BoxValue,
  BoldTextValue,
  ResponsiveBoldTextValue,
}

export default DisplayConfig
