import { bidlClient } from 'src/api/bidl/client'

const aggregatorCrmBase = 'api/aggregator/crm'

export const crmRequests = {
  createCustomer: (customer: CreateCustomerRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/CreateCustomer`, {
        json: customer,
      })
      .json<AggregatorBasicIdResponse>(),

  updateCustomer: (customer: Customer) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/UpdateCustomer`, {
        json: customer,
      })
      .json<AggregatorBasicIdResponse>(),

  searchCustomers: (value: string) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/SearchCustomer`, {
        json: {
          value,
        },
      })
      .json<SearchedCustomerResponse>(),

  getContactInformationByCustomerId: (id: string) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetContactInformationByCustomerId`, {
        json: { id },
      })
      .json<GetContactInformationByCustomerIdResponse>(),

  getDemographicByCustomerId: (id: string) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetDemographicByCustomerId`, {
        json: {
          id,
        },
      })
      .json<GetDemographicByCustomerIdResponse>(),

  updateDemographic: (demographic: UpdateDemographicRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/UpdateDemographic`, {
        json: demographic,
      })
      .json<UpdateDemographicResponse>(),

  createNote: (note: CreateNoteRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/CreateNote`, {
        json: note,
      })
      .json<Note>(),

  deleteNote: (noteId: DeleteNoteRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/DeleteNote`, {
        json: noteId,
      })
      .json<DeleteNoteResponse>(),

  getNote: (noteId: GetNoteRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetNote`, {
        json: noteId,
      })
      .json<Note>(),

  listNotesByCustomerId: (options: GetNotesByCustomerIdRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetNotesByCustomerId`, {
        json: options,
      })
      .json<GetNotesByCustomerIdResponse>(),

  updateNote: (note: UpdateNoteRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/UpdateNote`, {
        json: note,
      })
      .json<Note>(),

  createActivity: (request: CreateActivityRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/CreateActivity`, {
        json: request,
      })
      .json<AggregatorResponse<Activity, 'api.aggregator.crm.v1.response#CreateActivitySuccess'>>()
      .then((response) => response.data),

  updateActivity: (request: UpdateActivityRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/UpdateActivity`, {
        json: request,
      })
      .json<AggregatorResponse<Activity, 'api.aggregator.crm.v1.response#UpdateActivitySuccess'>>()
      .then((response) => response.data),

  deleteActivity: (id: string) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/DeleteActivity`, {
        json: { id },
      })
      .json<EmptyAggregatorResponse<'api.aggregator.crm.v1.response#DeleteActivitySuccess'>>(),

  completeTask: (activityId: string) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/CompleteTask`, {
        json: { id: activityId },
      })
      .json<AggregatorResponse<Activity, 'api.aggregator.crm.v1.response#CompleteTaskSuccess'>>()
      .then((response) => response.data),

  listActivitiesByCustomerId: (options: GetActivitiesByCustomerIdRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetActivitiesByCustomerId`, { json: options })
      .json<PaginatedAggregatorResponse<Activity>>(),

  getAssignedTasks: (options: GetAssignedTasksRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetAssignedTasks`, { json: options })
      .json<PaginatedAggregatorResponse<AssignedTask>>(),

  getAssignedStaffUsers: (customerId: string) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetAssignedStaffUsers`, { json: { id: customerId } })
      .json<
        AggregatorResponse<
          StaffUserAssignment[],
          'api.aggregator.crm.v1.response#GetAssignedStaffUsersSuccess'
        >
      >(),

  assignStaffUsers: (customerId: string, userIds: string[]) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/AssignStaffUsers`, { json: { customerId, userIds } })
      .json<
        AggregatorResponse<
          StaffUserAssignment[],
          'api.aggregator.crm.v1.response#AssignStaffUsersSuccess'
        >
      >(),

  unassignStaffUsers: (assignmentIds: string[]) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/UnassignStaffUsers`, { json: { ids: assignmentIds } })
      .json<EmptyAggregatorResponse<'api.aggregator.crm.v1#UnassignStaffUsersSuccess'>>(),

  getAssignedCustomers: () =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetAssignedCustomers`, { json: {} })
      .json<AggregatorResponse<AssignedCustomer[]>>(),

  getInvoiceLocationsByCustomerId: (id: string) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetInvoiceLocationsByCustomerId`, {
        json: { id },
      })
      .json<GetInvoiceLocationsByCustomerIdResponse>(),

  getDeliveryDestinationsByCustomerId: (id: string) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetDeliveryDestinationsByCustomerId`, {
        json: { id },
      })
      .json<GetDeliveryDestinationsByCustomerIdResponse>(),
}
