import { HTTPError } from 'src/api'
import { useConfig } from 'src/data/config'
import { DataColors } from 'src/themes/colors'
import { Column, Divider, Skeleton, SkeletonCircle } from 'src/components/designsystem'
import {
  DetailGrid,
  ErrorState,
  FormattedDateRange,
  renderOrEmDash,
  SkeleKeyValue,
} from 'src/components/resource'
import { ContractQuantitiesChart } from './contract-visuals'
import { PriceAmendments } from 'src/components/contracts/'
import AssociatedTickets from 'src/components/contracts/AssociatedTickets'
import { useContractDetail } from 'src/data/queries'

export interface ContractDetailProps {
  isLoaded?: boolean
  error?: HTTPError
  contractDetail: ContractDetail
}

export default function ContractDetail({
  isLoaded = false,
  error = null,
  contractDetail,
}: ContractDetailProps) {
  const { contracts: contractsConfig, tickets: ticketsConfig } = useConfig()
  const shouldFetchTicketApplications = contractsConfig.shouldFetchTicketApplications()
  const isTicketsEnabled = !!ticketsConfig.version()

  const contractHeaderLocation = contractsConfig.contractHeaderLocation()
  const showContractedLocation = contractsConfig.showContractedLocation()
  const showDeliveryLocation = contractsConfig.showDeliveryLocation()
  const currencyLabel = contractsConfig.showCurrency() ? contractDetail?.currency || '' : ''
  const contractIsClosed = contractDetail?.status === 'Closed'

  //99.9% threshold so that if it is almost filled, we consider it filled
  const hasRemaining =
    contractDetail?.quantity_remaining_numeric > contractDetail?.quantity_contracted_numeric * 0.001

  const shouldShowAssociatedTickets =
    shouldFetchTicketApplications &&
    isTicketsEnabled &&
    contractDetail?.ticket_applications?.length > 0

  if (error) {
    return (
      <ErrorState
        subHeader={
          error?.response?.payload?.error?.message ||
          "There was an error while retrieving this contract's details. Please refresh the page to try again."
        }
      />
    )
  }

  return (
    <>
      <DetailGrid.Title>Breakdown</DetailGrid.Title>
      <DetailGrid templateColumns={['1fr', null, '1fr 1fr', 'repeat(2, 1fr)']}>
        <DetailGrid.List>
          <DetailGrid.ListCard heading="Details">
            <SkeleKeyValue isLoaded={isLoaded} label="Contract Type">
              {renderOrEmDash({ value: contractDetail?.contract_type })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue isLoaded={isLoaded} label="Account ID">
              {renderOrEmDash({ value: contractDetail?.remote_user_id })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue isLoaded={isLoaded} label="Commodity">
              {renderOrEmDash({ value: contractDetail?.crop_name })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue isLoaded={isLoaded} label="Delivery Period">
              {renderOrEmDash({
                value:
                  contractDetail?.delivery_period_value ||
                  contractDetail?.delivery_period_start ||
                  contractDetail?.delivery_period_end,
                itemToRender: contractDetail?.delivery_period_value ?? (
                  <FormattedDateRange
                    start={contractDetail?.delivery_period_start}
                    end={contractDetail?.delivery_period_end}
                    localize={false}
                  />
                ),
              })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue isLoaded={isLoaded} label="Contract Status">
              {renderOrEmDash({ value: contractDetail?.status })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue isLoaded={isLoaded} label="Crop Year">
              {renderOrEmDash({ value: contractDetail?.crop_year })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue isLoaded={isLoaded} label="Price Basis">
              {renderOrEmDash({ value: contractDetail?.price_basis })}
            </SkeleKeyValue>
            {(showContractedLocation || contractHeaderLocation === 'contract') && (
              <>
                <Divider />
                <SkeleKeyValue isLoaded={isLoaded} label="Contracted Location">
                  {renderOrEmDash({ value: contractDetail?.contract_location_name })}
                </SkeleKeyValue>
              </>
            )}
            {(showDeliveryLocation || contractHeaderLocation === 'delivery') && (
              <>
                <Divider />
                <SkeleKeyValue isLoaded={isLoaded} label="Delivery Location">
                  {renderOrEmDash({ value: contractDetail?.delivery_location_name })}
                </SkeleKeyValue>
              </>
            )}
          </DetailGrid.ListCard>
          <DetailGrid.ListCard heading="Pricing">
            <SkeleKeyValue isLoaded={isLoaded} label="Futures Price">
              {renderOrEmDash({
                value: contractDetail?.futures_price,
                itemToRender: `${contractDetail?.futures_price} ${
                  contractDetail?.futures_price && currencyLabel
                }`,
              })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue isLoaded={isLoaded} label="Basis">
              {renderOrEmDash({
                value: contractDetail?.basis_cost,
                itemToRender: `${contractDetail?.basis_cost} ${
                  contractDetail?.basis_cost && currencyLabel
                }`,
              })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue isLoaded={isLoaded} label="Cash Price">
              {renderOrEmDash({
                value: contractDetail?.bid,
                itemToRender: `${contractDetail?.bid} ${contractDetail?.bid && currencyLabel}`,
              })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue isLoaded={isLoaded} label="Futures Month">
              {renderOrEmDash({ value: contractDetail?.futures_month })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue isLoaded={isLoaded} label="Currency">
              {renderOrEmDash({ value: contractDetail?.currency })}
            </SkeleKeyValue>
          </DetailGrid.ListCard>
        </DetailGrid.List>

        <DetailGrid.List>
          <DetailGrid.ListCard
            heading="Quantities"
            backgroundColor="gray.50"
            mt={[6, null, 0]}
            px={4}
          >
            <Column>
              {contractDetail ? (
                <ContractQuantitiesChart contract={contractDetail} />
              ) : (
                <>
                  <SkeletonCircle size="225px" />
                  <Skeleton height="20px" width="90px" />
                </>
              )}
            </Column>
            <SkeleKeyValue isLoaded={isLoaded} label="Qty Contracted">
              {renderOrEmDash({ value: contractDetail?.quantity_contracted })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue
              isLoaded={isLoaded}
              label="Qty Remaining"
              dotColor={
                contractDetail?.quantity_remaining_numeric > 0 ? DataColors['lightgray'] : null
              }
            >
              {renderOrEmDash({ value: contractDetail?.quantity_remaining })}
            </SkeleKeyValue>
            <Divider />
            <SkeleKeyValue
              isLoaded={isLoaded}
              label="Qty Delivered"
              dotColor={DataColors[contractIsClosed || !hasRemaining ? 'green' : 'yellow']}
            >
              {renderOrEmDash({ value: contractDetail?.quantity_submitted })}
            </SkeleKeyValue>
            {contractDetail?.quantity_canceled && contractDetail?.quantity_canceled_numeric > 0 && (
              <>
                <Divider />
                <SkeleKeyValue
                  isLoaded={isLoaded}
                  label="Qty Canceled"
                  dotColor={DataColors[contractIsClosed || !hasRemaining ? 'green' : 'red']}
                >
                  {renderOrEmDash({ value: contractDetail?.quantity_canceled })}
                </SkeleKeyValue>
              </>
            )}
          </DetailGrid.ListCard>
        </DetailGrid.List>
      </DetailGrid>

      {contractDetail?.price_amendments?.length > 0 && (
        <>
          <PriceAmendments
            priceAmendments={contractDetail.price_amendments}
            currencyLabel={currencyLabel}
            previewAmount={5}
          />
        </>
      )}
      {shouldShowAssociatedTickets && (
        <AssociatedTickets ticketApps={contractDetail?.ticket_applications} />
      )}
    </>
  )
}

export function ContractDetailWithData({ id }: { id: number }) {
  const contractDetailQuery = useContractDetail({ id })

  return (
    <ContractDetail
      isLoaded={contractDetailQuery.isSuccess}
      error={contractDetailQuery.error}
      contractDetail={contractDetailQuery.data}
    />
  )
}
