import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { create } from 'zustand'
import { trackEvent } from 'src/utils/analytics'

type CRMSearchStore = {
  isOpen: boolean
  search: string
  onOpen: () => void
  onClose: () => void
  setSearch: (search: string) => void
}

const useSearchStore = create<CRMSearchStore>()((set) => ({
  isOpen: false,
  search: '',
  onOpen: () => {
    set({ isOpen: true })
    trackEvent('Customer View', 'Start Search')
  },
  onClose: () => {
    set({ isOpen: false, search: '' })
    trackEvent('Customer View', 'End Search')
  },
  setSearch: (search: string) => {
    set({ search: search })
  },
}))

export function useCustomerSearchDisclosure() {
  const { query, push } = useRouter()
  const { search, isOpen, onOpen, onClose, setSearch } = useSearchStore()
  const company = query.company?.toString()

  return {
    search,
    setSearch,
    isOpen,
    onOpen,
    onClose,

    onSelectCustomer: useCallback(
      (customer: SearchedCustomer) => {
        push(`/${company}/staff/crm/${encodeURIComponent(customer.id)}`, undefined, {})
        trackEvent('Customer View', 'Selected Customer')
        onClose()
      },
      [push, company, onClose]
    ),
  }
}
