import ky from 'ky'
import handleRefreshTokenIfExpired from 'src/api/utils/handleRefreshTokenIfExpired'
import { ENV } from 'src/utils/env'
import { getWindowNextDataProps } from 'src/utils/get-window-next-data'

const { token, slug } = getWindowNextDataProps()

let _futuresServiceClient = ky.create({
  prefixUrl: ENV.FUTURES_SERVICE_API,
  retry: 0,
  timeout: 120000, //2 mins
  headers: {
    Authorization: token ? `Bearer ${token}` : undefined,
    'App-Company': slug ?? undefined,
  },
  hooks: {
    beforeRequest: [handleRefreshTokenIfExpired],
    afterResponse: [
      async (_request, _options, response) => {
        if (!response.ok) {
          try {
            const body = await response.text()

            console.error('src/api - api request failed', {
              url: response.url,
              status: response.status,
              body,
            })

            response.payload = JSON.parse(body)
          } catch (err) {
            console.error('src/api - api request failed', {
              url: response.url,
              status: response.status,
              message: err.message,
            })
          }
        }

        return response
      },
    ],
  },
})

export const futuresServiceClient = _futuresServiceClient

export const updateFuturesServiceClient = (config = {}) => {
  _futuresServiceClient = _futuresServiceClient.extend(config)
}

export const updateFuturesServiceToken = (token?: string) => {
  updateFuturesServiceClient({ headers: { Authorization: token ? `Bearer ${token}` : undefined } })
}
